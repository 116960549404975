<template>
    <div class="sweepstakes_wrap">
        <!--<h1>情满中秋节，好礼送不停</h1>-->
            <div class="act_title" style="margin-top: 8rem;">
                <img src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/2cb610628c27531ce8717f61bccc5949.png" alt="伟业计量情满中秋节，好礼送不停" style="width:100%;">
            </div>
        <div class="sweepstakes_title">
<!--            <p style="font-weight: bold;">活动内容</p>-->
            <span class="sweepstakes_huida">回答内容即可抽奖</span>
            <div class="sweepstakes_guan">
                我们的官网是？www.<input type="text" name="name" id="Q_answer" class="sweepstakes_input" maxlength="4"/>.com，其中bzwz是
                <select class="challengeWhellSelect" style="background-color: #96090F;border-color: #96090F;width: 110px;">
                    <option value="kzyz"></option>
                    <option value="azwz">安装位置</option>
                    <option value="czwz">沉着稳重</option>
                    <option value="bzwz">标准物质</option></select
                >的拼音首字母
            </div>
        </div>

        <div class="sweepstakes_content">
            <canvas class="Sweepstakes_dibu" id="myCanvas" width="600" height="600">
                当前浏览器版本过低，请使用其他浏览器尝试
            </canvas>
            <img class="Sweepstakes_start" src="@/assets/img/lottery/start.png" id="start">
        </div>
        <div class="sweepstakes_bottom">
<!--            <router-link-->
<!--                :to="{-->
<!--                    name: 'Message',-->
<!--                }"-->
<!--            >-->
<!--                <a class="sweepstakes_btn">查看奖品</a>-->
<!--            </router-link>-->
            <div class="Sweepstakes_guize">
                <!--<span class="Sweepstakes_guize_title" style="margin-top:0.3rem">活动规则</span>-->
                <div class="act_title">
                     <img src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/cf03c0fea095d030814bf8f0366e72c2.png" alt="伟业计量情满中秋节，好礼送不停" style="width:100%;">
                </div>
                <div class="Sweepstakes_guize_content">
                    <span class="huodong" style="margin-top:0.4rem;">1、本次活动对象是伟业计量新老客户。</span>
                    <span class="huodong">2、活动时间：2021年09月13日——2021年09月18日。</span>
                    <span class="huodong">3、活动期间每人共有两次抽奖机会，伟业PC端一次，伟业微信公众号一次。</span>
                    <span class="huodong">4、抽奖礼品兑换后，将在一周内寄出，请耐心等待。</span>
                    <span class="huodong">注：本活动解释权最终归伟业计量所有。</span>
                </div>
            </div>
        </div>
        <red-prize
          v-if="showRedPrize"
          @close="onClosePrize"
          :src="awardImg"
          :awardName="awardName"
          hint="恭喜您获得"
        ></red-prize>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, inject, onMounted, nextTick } from "vue";
import $ from "jquery";
import Toast from "@/components/UI/Toast";
import axios from "@/api/axios";
import Modal from "@/components/UI/Modal";
import RedPrize from "@/mid/RedPrize.vue";
import { useRouter } from "vue-router";

import qs from "qs";
import asyncLoadJs, { removeJs } from "@/hooks/useAsyncLoadJs";
export default defineComponent({
    name: "MidDetail",
    components: {
      RedPrize
    },
    setup(){

      ($.fn as any).WheelSurf = function (options: any) {
          const _default = {
              outerCircle: {
                  color: '#df1e15'
              },
              innerCircle: {
                  color: '#f4ad26'
              },
              dots: ['#fbf0a9', '#fbb936'],
              disk: ['#ffb933', '#ffe8b5', '#ffb933', '#ffd57c', '#ffb933', '#ffe8b5', '#ffd57c'],
              title: {
                  color: '#5c1e08',
                  font: '19px Arial'
              }
          }

          $.extend(_default,options)
          // 画布中心移动到canvas中心

          /* eslint-disable */
          const _this = this[0],
              width = _this.width,
              height = _this.height,
              ctx = _this.getContext("2d"),
              imgs: any = [],
              awardTitle: any = [],
              awardPic: any = []
          for (const item in (_default as any).list) {
              awardTitle.push((_default as any).list[item].name)
              imgs.push((_default as any).list[item].image)
          }
          const num = imgs.length
          // 圆心
          const x = width / 2
          const y = height / 2
          ctx.translate(x, y)

          return {
              init: function (angelTo: any) {
                  angelTo = angelTo || 0;

                  ctx.clearRect(-this.width, -this.height, this.width, this.height);

                  // 平分角度
                  const angel = (2 * Math.PI / 360) * (360 / num);
                  let startAngel = 2 * Math.PI / 360 * (-90)
                  let endAngel = 2 * Math.PI / 360 * (-90) + angel

                  // 旋转画布
                  ctx.save()
                  ctx.rotate(angelTo * Math.PI / 180);
                  // 画外圆
                  ctx.beginPath();
                  ctx.lineWidth = 25;
                  ctx.strokeStyle = _default.outerCircle.color;
                  ctx.arc(0, 0, 243, 0, 2 * Math.PI)
                  ctx.stroke();
                  // 画里圆
                  ctx.beginPath();
                  ctx.lineWidth = 23;
                  ctx.strokeStyle = _default.innerCircle.color;
                  ctx.arc(0, 0, 218, 0, 2 * Math.PI)
                  ctx.stroke();

                  // 装饰点
                  const dotColor = _default.dots
                  for (let i = 0; i < 12; i++) {
                      // 装饰点 圆心 坐标计算
                      ctx.beginPath();
                      const radius = 230;
                      const xr = radius * Math.cos(startAngel)
                      const yr = radius * Math.sin(startAngel)

                      ctx.fillStyle = dotColor[i % dotColor.length]
                      ctx.arc(xr, yr, 11, 0, 2 * Math.PI)
                      ctx.fill()

                      startAngel += (2 * Math.PI / 360) * (360 / 12);

                  }
                  // 画里转盘                
                  const colors = _default.disk
                  for (let i = 0; i < num; i++) {
                      ctx.beginPath();
                      ctx.lineWidth = 208;
                      ctx.strokeStyle = colors[i % colors.length]
                      ctx.arc(0, 0, 104, startAngel, endAngel)
                      ctx.stroke();
                      startAngel = endAngel
                      endAngel += angel
                  }
                  // 添加奖品
                  function loadImg() {

                      const dtd = $.Deferred()
                      let countImg = 0
                      if (awardPic.length) {
                          return dtd.resolve(awardPic);
                      }
                      for (let i = 0; i < num; i++) {
                          const img = new Image()
                          awardPic.push(img)

                          img.src = imgs[i]
                          img.onload = function () {
                              countImg++
                              if (countImg == num) {
                                  dtd.resolve(awardPic);
                              }
                          }
                      }
                      return dtd.promise()
                  }

                  $.when(loadImg()).done(function (awardPic) {

                      startAngel = angel / 2
                      for (let i = 0; i < num; i++) {
                          ctx.save();
                          ctx.rotate(startAngel)
                          ctx.drawImage(awardPic[i], -36, -23 - 130, 70, 70);
                          ctx.font = _default.title.font;
                          ctx.fillStyle = _default.title.color
                          ctx.textAlign = "center";
                          ctx.fillText(awardTitle[i], 0, -170);
                          startAngel += angel
                          ctx.restore();
                      }
                  })
                  ctx.restore();
              },
              /**
               * @param angel 旋转角度
               * @param callback 转完后的回调函数
               */
              lottery: function (angel: any, callback: any) {
                  angel = angel || 0
                  angel = 360-angel
                  angel += 720
                  // 基值（减速）
                  const baseStep = 30
                  // 起始滚动速度
                  const baseSpeed = 0.3
                  // 步长
                  let count = 1;
                  const _this = this
                  const timer = setInterval(function () {

                      _this.init(count)
                      if (count == angel) {
                          clearInterval(timer)
                          if (typeof callback == "function") {
                              callback()
                          }
                      }

                      count = count + baseStep * (((angel - count) / angel) > baseSpeed ? baseSpeed : ((angel - count) / angel))
                      if (angel - count < 0.5) {
                          count = angel
                      }

                  }, 25)
              }
          }

      }

      const showRedPrize = ref(false)
      const awardName = ref()
      const awardImg = ref()
      const router = useRouter();

      var wheelSurf: any
      // 初始化装盘数据 正常情况下应该由后台返回
      var initData = {
          "success": true,
          "list": [
              {
                  "id": 1432526940572688385,
                  "name": "华为笔记本",
                  "image": "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Event/15ad56957a2587dde2ee737715f52088.png",
                  "rank": 1,
                  "percent": 10
              },
              {
                "id": 1432528570131095553,
                "name": "3888积分",
                "image": "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Event/fb804f34b04bed2150f0b1a50c2d5dc2.png",
                "rank": 2,
                "percent": 10
              },
              {
                "id": 1432527050241155073,
                "name": "美的洗地机",
                "image": "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Event/4d5a51a52561ed19bb190e80dcc37a5c.png",
                "rank": 3,
                "percent": 10
            },
            {
                "id": 1432528666876911618,
                "name": "1888积分",
                "image": "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Event/fb804f34b04bed2150f0b1a50c2d5dc2.png",
                "rank": 4,
                "percent": 15
            },
            {
                "id": 1432527151797837825,
                "name": "美的刀具筷子除菌机",
                "image": "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Event/07963720515b5f55ec8554051602eac2.png",
                "rank": 5,
                "percent": 10
            },
            {
                "id": 1432528747243970562,
                "name": "588积分",
                "image": "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Event/fb804f34b04bed2150f0b1a50c2d5dc2.png",
                "rank": 6,
                "percent": 10
            },
            {
                "id": 1432528425549242369,
                "name": "月饼礼盒",
                "image": "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Event/68bea3b51f48bf517c5d181566502cb3.png",
                "rank": 7,
                "percent": 20
            },
            {
                "id": 1432528850725838849,
                "name": "100元代金券",
                "image": "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Event/8efed3c9533405685445888c37cfbb70.png",
                "rank": 8,
                "percent": 15
            },
        ]
      }

      // 计算分配获奖概率(前提所有奖品概率相加100%)
      function getGift() {
          var percent = Math.random() * 100
          var totalPercent = 0
          for (var i = 0, l = initData.list.length; i < l; i++) {
              totalPercent += initData.list[i].percent
              if (percent <= totalPercent) {
                  console.log(initData.list[i]);
                  return initData.list[i];
              }
          }
      }

      var list = {}
      var angel = 360 / initData.list.length
      // 格式化成插件需要的奖品列表格式
      for (var i = 0, l = initData.list.length; i < l; i++) {
          list[initData.list[i].rank] = {
              id: initData.list[i].id,
              name: initData.list[i].name,
              image: initData.list[i].image
          }
      }
      var throttle = true;


      onMounted(() => {
        // 定义转盘奖品
        wheelSurf = ($('#myCanvas') as any).WheelSurf({
            list: list, // 奖品 列表，(必填)
            outerCircle: {
                color: '#e74b44' // 外圈颜色(可选)
            },
            innerCircle: {
                color: '#f4ad26' // 里圈颜色(可选)
            },
            //dots: ['#fbf0a9', '#fbb936'], // 装饰点颜色(可选)
            dots: ['#fff7af', '#fafffe'],
            //disk: ['#ffb933', '#ffe8b5', '#ffb933', '#ffd57c', '#ffb933', '#ffe8b5', '#ffd57c'], //中心奖盘的颜色，默认7彩(可选)
            disk: ['#ffeaa7', '#fcf9f0', '#ffeaa7', '#fcf9f0', '#ffeaa7', '#fcf9f0', '#ffeaa7', '#fcf9f0'],
            title: {
                color: '#5c1e08',
                font: '16px Arial'
            } // 奖品标题样式(可选)
        })
        // 初始化转盘
        wheelSurf.init()

        $("#Q_answer").blur(function () {
          const answer = ($("#Q_answer").val() as any).trim().toLowerCase();
          if (answer == "") {
            Toast({
              title: "要先输入网址才能抽奖哦",
              duration: 2000,
              type: "warning"
            });

            $(this).focus();
            return false;
          }
          if (answer != "bzwz") {
            Toast({
              title: "您的网址似乎输入错误，提示：答案是标准物质的首字母bzwz",
              type: "warning",
              duration: 2000
            });

            $(this).focus();
            return false;
          }
        });
        $(".challengeWhellSelect").blur(function () {
          const challengeWhellSelect = $(".challengeWhellSelect").val();
          if (challengeWhellSelect != "bzwz") {
            //layer.msg("选择错误。bzwz是“标准物质”的拼音首字母", { time: 1500 });
            Toast({
              title: "选择错误。bzwz是“标准物质”的拼音首字母",
              type: "warning",
              duration: 2000,
            });

            return false;
          }
        });
        $("#start").on('click', function () {
            const token = localStorage.getItem("token");
            if (!token) {
              Modal({
                title: "温馨提示",
                content: "登录后才可以进行抽奖哦~",
                onClose: () => {
                  router.push({
                    name: "Login",
                    query: {
                      redirect: router.currentRoute.value.fullPath,
                    },
                  });
                },
              });
              return false;
            }
            const answer = ($("#Q_answer").val() as any).trim().toLowerCase();
            const challengeWhellSelect = $(".challengeWhellSelect").val();
            if (answer == "") {
              Toast({
                title: "要先输入网址才能抽奖哦",
                type: "warning",
                duration: 2000,
              });

              $("#Q_answer").focus();
              return false;
            }
            if (answer != "bzwz") {
              Toast({
                title: "您的网址似乎输入错误，提示：答案是标准物质的首字母bzwz",
                type: "warning",
                duration: 2000,
              });
              $("#Q_answer").focus();
              return false;
            }
            if (challengeWhellSelect != "bzwz") {
              Toast({
                title: "选择错误。bzwz是“标准物质”的拼音首字母",
                type: "warning",
                duration: 2000,
              });
              return false;
            }
            var winData: any; // 正常情况下获奖信息应该由后台返回

            axios
              .post("/M/Event/midAutumnFestivalLuckDraw",
                qs.stringify({
                  idstr: '1432522244126613505',
                  tag: 1,
                  answer: answer
                })
              )
              .then((res: any) => {
                // console.log(data);
                if (res.data.success) {
                    // winData = eval("(" + data.data + ")");
                    winData = res.data.data
                    $("#Message").text('')
                    throttle = false;
                    var count = 0
                    // 计算奖品角度
                    for (var key in list) {
                        if (list.hasOwnProperty(key)) {
                            if (winData.id == list[key].id) {
                                winData.image = list[key].image
                                break;
                            }
                            count++
                        }
                    }

                    // 转盘抽奖，
                    wheelSurf.lottery((count * angel + angel / 2), function () {
                        // $("#Message").text(winData.rewardName);
                        // $("#winnerIMG").attr("src", winData.image);
                        awardName.value = winData.rewardName
                        awardImg.value = winData.image
                        showRedPrize.value = true
                        throttle = true;
                    })

                } else if (res.data.msg =='系统找不到默认地址，如果没有设置默认地址，请先设置！') {
                    Modal({
                      title: "温馨提示",
                      content: "系统找不到默认地址，如果没有设置默认地址，请先设置！",
                      onClose: () => {
                          router.push({
                              name: "Address"
                          });
                      },
                    });
                } else {
                    Toast({
                      title: res.data.msg,
                      type: "error",
                      duration: 2000,
                    });
                }
              })
              .catch((err) => {
                Toast({
                      title: '执行错误，请联系管理员！',
                      type: "error",
                    });
              });
        });

      })

      function onClosePrize() {
        showRedPrize.value = false;
        router.push({
          name: "Intergral"
        });
      }

      return {
        showRedPrize,
        onClosePrize,
        awardImg,
        awardName
      }
    }
});
</script>


<style lang="scss" scoped>

.sweepstakes_wrap {
    padding: 0.2rem;
    background: url("https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/43c4bbe8c1abbcc60087751dd19f1c8c.jpg") no-repeat;
    background-size: 100%;
    background-color: #96090D;
}

.sweepstakes_wrap h1 {
    text-align: center;
    line-height: 40px;
    color: #666666;
    font-size: 22px;
}

.sweepstakes_title {
  margin-top: 10px;
  color: #666;
  background: url("https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/01b44049242315f7e80f1f1b4db0c739.png") no-repeat;
    background-size: 100%;
    height: 4rem;;
}

.sweepstakes_title textarea {
  width: 100%;
  font-size: 14px;
  padding: 5px;
  border: 1px solid #808080;
}

.sweepstakes_title .sweepstakes_title_unpack,
.Sweepstakes_guize_title {
  font-size: 16.5px;
  font-weight: bold;
  position: relative;
  padding-left: 10px;
}

.sweepstakes_title .sweepstakes_title_unpack::after,
.Sweepstakes_guize_title::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 2px;
  width: 3px;
  height: 17.5px;
  background: #df0024;
}

.sweepstakes_huida {
    display: inline-block;
    width:100%;
    text-align: center;
    line-height: 0.3rem;
    font-size: 16.5px;
    margin-top: 0.6rem;
    color: #fff;
}

.sweepstakes_guan {
    margin-top: 10px;
    font-size: 16.5px;
    font-weight: 500;
    margin-bottom: 10px;
    line-height: 30px;
    color: #fff;
    padding: 0.2rem 0.4rem 0.2rem 0.8rem;
}

.sweepstakes_guan input {
  width: 65px;
  height: 25px;
  line-height: 25px;
  border: none;
  border-bottom: 1px solid;
  outline: none;
  text-align: center;
  font-size: 16.5px;
    background-color: #96090F;
  color: #fff;
  overflow: hidden;
}

/*.sweepstakes_title span {*/
/*font-size: 0.25rem;*/
/*display: inline-block;*/
/*}*/

.sweepstakes_title span a {
    color: #0072bb;
}

    .sweepstakes_btn {
        display: inline-block;
        width: 100%;
        height: 0.9rem;
        background-color: #d64b4b;
        box-shadow: 0px 0px 0.2rem 0px rgba(0, 0, 0, 0.2);
        border-radius: 0.05rem;
        line-height: 0.9rem;
        text-align: center;
        color: #fff !important;
        font-size: 0.4rem;
    }

    .Sweepstakes_guize {
        margin-top: 20px;
        text-align: left;
        color: #666;
    }

    .Sweepstakes_guize_content {
        background: url("https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/b6e2bdea0187f2ebb53e8215c90683bf.png") no-repeat;
        background-size: 100%;
        height: 6.2rem;
        margin-bottom: 1rem;
    }

    .sweepstakes_content {
        text-align: center;
        position: relative;
        padding: 0.4rem 0;
    }

    .Sweepstakes_start {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -0.75rem;
        margin-top: -0.75rem;
        cursor: pointer;
        width: 1.5rem;
    }

    .Sweepstakes_dibu {
        width: 100%;
    }

    .challengeWhellSelect {
        width: 2.4rem;
        height: 0.65rem;
        line-height: 0.65rem;
        background: #96090F;
        font-size:16px;
        color:#fff;
        font-family: 'Microsoft YaHei';
        border:1px solid #fff;
    }

    .challengeWhellTitle {
        text-align: center;
        font-size: 0.28rem;
        line-height: 0.5rem;
    }

    .Sweepstakes_bottom_content {
        line-height: 0.6rem;
    }

        .Sweepstakes_bottom_content p a {
            text-decoration: underline;
            color: #2a2aca;
        }

    .Choice {
        width: 100%;
        height: auto;
        margin-top: 0.1rem;
    }

    label {
        display: block;
        width: 100%;
        font-size: 16px;
        margin-bottom: 10px;
    }

    .Choice input {
        vertical-align: middle;
        margin-right: 10px;
    }

    .lottery-text {
    position: absolute;
    left: 11%;
    top: 49.5%;
    width: 80%;
    /* margin-left: -40%; */
    z-index: 20;
    color: #ec3f3f;
    font-size: 0.20rem;
    text-align: center;
}

#winnerIMG {
    width: 1.5rem;
    height: 1.3rem;
    position: absolute;
    top: -1.8rem;
    right: 1.25rem;
}
.act_title{
    width:100%;
    margin:0 auto;
    text-align: center;
    margin: 0.5rem 0;
}

.huodong {
    display: block;
    color: #fff;
    font-size: 13px;
    padding: 5px 20px;
}

.huodong:first-child {
    padding-top: 18px;
}
</style>